// Generated by Framer (241a4b4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/UsuXAzxDZ";

const cycleOrder = ["eyn86esx3"];

const variantClassNames = {eyn86esx3: "framer-v-93r6ij"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, Ab6c9DL14: title ?? props.Ab6c9DL14 ?? "Get Started", jX6qdNKJS: link ?? props.jX6qdNKJS} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Ab6c9DL14, jX6qdNKJS, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "eyn86esx3", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-mXPqK", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={jX6qdNKJS} openInNewTab={false}><motion.a {...restProps} className={`${cx("framer-93r6ij", className)} framer-144fdqo`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"eyn86esx3"} ref={ref} style={{backgroundColor: "rgb(218, 95, 111)", borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24, ...style}}><motion.div className={"framer-yemv5q"} data-framer-name={"texts"} layoutDependency={layoutDependency} layoutId={"MJrTvT7qN"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1y4pmze"} data-styles-preset={"UsuXAzxDZ"} style={{"--framer-text-color": "var(--extracted-r6o4lv)"}}>Get Started</motion.p></React.Fragment>} className={"framer-fr3mto"} data-framer-name={"Almost before we kne"} layoutDependency={layoutDependency} layoutId={"I819:4316;814:4450"} style={{"--extracted-r6o4lv": "var(--token-765d4d1c-9f94-47f0-8b63-48d5fa27cccf, rgb(254, 254, 254))", "--framer-paragraph-spacing": "0px"}} text={Ab6c9DL14} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-mXPqK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mXPqK .framer-144fdqo { display: block; }", ".framer-mXPqK .framer-93r6ij { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 10px 24px 10px 24px; position: relative; text-decoration: none; width: min-content; }", ".framer-mXPqK .framer-yemv5q { align-content: flex-start; align-items: flex-start; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-mXPqK .framer-fr3mto { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-mXPqK .framer-93r6ij, .framer-mXPqK .framer-yemv5q { gap: 0px; } .framer-mXPqK .framer-93r6ij > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-mXPqK .framer-93r6ij > :first-child { margin-top: 0px; } .framer-mXPqK .framer-93r6ij > :last-child { margin-bottom: 0px; } .framer-mXPqK .framer-yemv5q > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-mXPqK .framer-yemv5q > :first-child { margin-left: 0px; } .framer-mXPqK .framer-yemv5q > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 39
 * @framerIntrinsicWidth 136
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"Ab6c9DL14":"title","jX6qdNKJS":"link"}
 */
const FramerBkte8AAMr: React.ComponentType<Props> = withCSS(Component, css, "framer-mXPqK") as typeof Component;
export default FramerBkte8AAMr;

FramerBkte8AAMr.displayName = "cta-small";

FramerBkte8AAMr.defaultProps = {height: 39, width: 136};

addPropertyControls(FramerBkte8AAMr, {Ab6c9DL14: {defaultValue: "Get Started", displayTextArea: false, title: "Title", type: ControlType.String}, jX6qdNKJS: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerBkte8AAMr, [...sharedStyle.fonts])